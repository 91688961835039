@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: linear-gradient(
  -45deg,
  #C9D6FF,
  #E2E2E2
);
background-size: 400% 400%;
animation: gradientBG 10s ease infinite;
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/* main css */

.hide{
  display: none;
}

.navbar_heading{
  font-size: 6vh;
  font-weight: 500;
  color: #000000;
  font-family: 'Playfair Display', serif;
}


.container_main{
  display: flex;
  justify-content: center;
}

.navbar{
  text-align: center;
  margin-top: -30px;
}


#container {
  justify-content: center;
  width: max-content;
}

#container button {
  margin: .3em;
  width: 5em;
  height: 5em;
  border-radius: 14px;
  border-color: #89a9a9;
}

.button_div{
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

#start{
    width: 150px;
    height: 60px;
    border-radius: 10px;
    background-color: rgb(130, 242, 204);
    text-align: center;
    font-size: large;
    font-family: 'Playfair Display', serif;
}


/* loader */

.loader-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #242f3f;
  display:flex;
  justify-content: center;
  align-items: center;
}
.loader {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  border: 4px solid #Fff;
  animation: loader 2s infinite ease;
}
.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #fff;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% { transform: rotate(0deg);}
  25% { transform: rotate(180deg);}
  50% { transform: rotate(180deg);}
  75% { transform: rotate(360deg);}
  100% { transform: rotate(360deg);}
}

@keyframes loader-inner {
  0% { height: 0%;}
  25% { height: 0%;}
  50% { height: 100%;}
  75% { height: 100%;}
  100% { height: 0%;}
}
